import * as React from "react";
import "overlayscrollbars/css/OverlayScrollbars.min.css";
import OverlayScrollbars from "overlayscrollbars";
import styles from "./ScrollContainer.module.scss";
import { navService } from "../../services/nav";
import cx from "classnames";

//   OverlayScrollbars(document.querySelectorAll('.scrollWrapper'), {
//     //autoUpdate: true,
//     sizeAutoCapable: false
//   });

export default class ScrollContainer extends React.Component<
  any,
  {
    scrollIndicatorVisible: boolean;
    scrolledToEnd: boolean;
  }
> {
  divRef: React.RefObject<HTMLDivElement>;
  scrollIndicatorRef: React.RefObject<HTMLDivElement>;
  instance: OverlayScrollbars | null = null;

  constructor(props: any) {
    super(props);
    this.divRef = React.createRef();
    this.scrollIndicatorRef = React.createRef();

    this.state = {
      scrollIndicatorVisible: false,
      scrolledToEnd: false
    };
  }
  componentDidMount() {
    this.attach();

    window.addEventListener("resize", this.attach);
    navService.onChange(() => {
      this.scroll();
    });
  }
  scroll = () => {
    if (this.instance) {
      this.instance.scroll(0);
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.attach);
  }
  attach = () => {
    if (this.divRef.current && !this.isMobile() && !this.instance) {
      this.instance = OverlayScrollbars(this.divRef.current, {
        callbacks: {
          onScrollStop: e => {
            if (this.instance && e) {
              this.setState({
                scrolledToEnd:
                  (e.target as HTMLElement).scrollTop >=
                  this.instance.getState().overflowAmount.y
              });
            }
          },
          onOverflowChanged: e => {
            if (this.instance && this.divRef.current) {
              const scrollState = this.instance.getState();
              this.setState({
                scrollIndicatorVisible: scrollState.hasOverflow.y,
                scrolledToEnd:
                  this.divRef.current.scrollTop >= scrollState.overflowAmount.y
              });
            }
          }
        },
        sizeAutoCapable: false,
        scrollbars: {
          // autoHide: 'leave'
        }
      });

      const scrollState = this.instance.getState();

      this.setState({
        scrollIndicatorVisible: scrollState.hasOverflow.y,
        scrolledToEnd:
          this.divRef.current.scrollTop >= scrollState.overflowAmount.y
      });
    } else if (this.isMobile() && this.instance) {
      this.instance.destroy();
      this.instance = null;

      this.setState({
        scrollIndicatorVisible: false
      });
    }
  };
  isMobile = () => {
    if (window.matchMedia) {
      if (window.matchMedia("(max-width: 640px)").matches) {
        return true;
      }
    }
    return false;
  };
  render() {
    return (
      <div className={styles.ScrollContainer}>
        <div ref={this.divRef} className={this.props.className}>
          {this.props.children}
        </div>
        <div
          className={cx(styles.ScrollIndicator, {
            [styles.Visible]:
              this.state.scrollIndicatorVisible && !this.state.scrolledToEnd
          })}
        />
      </div>
    );
  }
}
