import * as React from "react";
import Nav from "../Nav/Nav";
import cx from "classnames";

import styles from "./App.module.scss";

// import BackgroundCanvas from '../BackgroundCanvas/BackgroundCanvas';
import Content from "../Content/Content";
import { navService } from "../../services/nav";
import { withRouter, RouteComponentProps } from "react-router";

//     this.activateIndicator(this.currentIndex);

const App: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const [actualIndicator, setIndicator] = React.useState<number>(-1);
  const [backgroundColorIndex, setBackgroundColorIndex] = React.useState(0);

  React.useEffect(() => {
    navService.setHistory(props.history);
    if (actualIndicator === -1) {
      setIndicator(navService.currentIndex);
      setBackgroundColorIndex(navService.currentIndex);
    }

    const checkChanges = (which?: number) => {
      setBackgroundColorIndex(which || 0);
      setIndicator(
        typeof which !== "undefined" ? which : navService.currentIndex
      );
    };
    return navService.onChange(checkChanges);
  }, [actualIndicator, backgroundColorIndex, props.history]);

  return (
    <React.Fragment>
      <div className={styles.siteWrapper}>
        <div className={styles.leftColumn}>
          <div
            className={cx(
              styles.columnColorOverlay,
              styles[`shift-${backgroundColorIndex}`]
            )}
          />
          <header className={styles.header}>
            <div className={styles.name}>Hubert Zub</div>
            <div className={styles.job}>
              front-end / JavaScript developer and consultant
            </div>
            <div className={cx(styles.job, styles.small)}>
              + a bit of travel & photography
            </div>
          </header>
          <Nav />
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.contentWrapper}>
            <Content />
          </div>
        </div>
      </div>
      <div className={`siteIndicator js-indicator`}>
        <span
          data-index="1"
          className={`js-indicator-item ${actualIndicator === 0 && "shown"}`}
        >
          <i className="icon-home"></i>
        </span>
        <span
          data-index="3"
          className={`js-indicator-item ${actualIndicator === 1 && "shown"}`}
        >
          <i className="icon-book-open"></i>
        </span>
        <span
          data-index="4"
          className={`js-indicator-item ${actualIndicator === 2 && "shown"}`}
        >
          <i className="icon-newspaper"></i>
        </span>
        <span
          data-index="5"
          className={`js-indicator-item ${actualIndicator === 4 && "shown"}`}
        >
          <i className="icon-mobile"></i>
        </span>
      </div>
    </React.Fragment>
  );
};

export default withRouter(App);
