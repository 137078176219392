import * as React from "react";
import styles from "./Resume.module.scss";

export default class ResumePage extends React.Component {
  render() {
    return (
      <div>
        <h2>profile</h2>
        <section>
          <p>
            Full-stack developer and trainer with 13 years of experience,
            strongly focused on development using JavaScript and related
            technologies. Worked as a programmer and consultant for both
            startups and enterprise-grade projects for Fortune500 companies.
          </p>
          <p>
            Experienced in the full stack, focused on the front-end part. Helped
            to build application architectures for both monolith and
            microservice solutions. Used to be a technical leader in quite a few
            projects and worked with various methodologies in different
            environments. Involved in IT education and open-source projects.
          </p>
        </section>
        <h2>experience</h2>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2022 – </span>
          <div>
            <span className={styles.company}>
              <a href="https://databricks.com/" target="_blank">
                Databricks, Inc. (San Francisco)
              </a>
            </span>
            <span className={styles.company}>Front-end Engineer</span>
            <p className={styles.description}>
              Responsible for development and maintenance of Databricks machine
              learning UI. Working with multiple teams and customers to develop
              and improve web interfaces used by 10,000s of enterprise
              customers. Driving documentiation and implementation of new
              features related to cutting-edge AI tooling. Taking care of
              underlying infrastructure and testing foundation.
            </p>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2019 – 2022</span>
          <div>
            <span className={styles.company}>EYEDEA AS (Norway)</span>
            <span className={styles.company}>Chief Technical Officer</span>
            <p className={styles.description}>
              Leading a group of full-stack developers towards the delivery of
              applications and prototypes. Worked with Working with a highly
              specific BDD-based prototyping approach and a stack using React,
              Nest.js, serverless node.js based code and GCP with Kubernetes.
              Working directly with customers, assessing the improvement of
              developers’ skills.
            </p>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2015 – 2020</span>
          <div>
            <span className={styles.company}>TenderHut SA (Poland)</span>
            <span className={styles.company}>
              Head of front-end development
            </span>
            <p className={styles.description}>
              Duties involved building core front-end developer team, assessing
              improvement of their skills and defining core technical elements
              of the projects - i.e. proper architecture and approaches. A
              significant portion of my work was devoted to supporting the sales
              department, i.e. helping build relationships with clients as a
              technical representative and analyzing their business needs. I’ve
              successfully built a few project teams and established two
              front-end teams: in Białystok and Wrocław company branches.
            </p>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2020 –</span>
          <div>
            <span className={styles.company}>WOW School</span>
            <span className={styles.company}>Course creator and mentor</span>
            <p className={styles.description}>
              Created "Creating websites and programming WWW applications"
              course. Covering communication with the students, addressing their
              problems and hosting webinars. .
            </p>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2019 –</span>
          <div>
            <span className={styles.company}>Coders.guide</span>
            <span className={styles.company}>Founder and curator</span>
            <p className={styles.description}>
              Interactive roadmap for learning how to code, including progress
              tracker and curated resource links. Available on{" "}
              <a
                href="https://coders.guide"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://coders.guide
              </a>
              .
            </p>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2019 – 2020</span>
          <div>
            <span className={styles.company}>GraphQL Editor Ltd</span>
            <span className={styles.company}>Part-time technical advisor</span>
            <p className={styles.description}>
              Periodically running technical Q&A sessions, aiding in building
              product's roadmap and vision.
            </p>
          </div>
        </section>

        <section className={styles.columned}>
          <span className={styles.dateblock}>2014 – 2015</span>
          <div>
            <span className={styles.company}>Transition Technologies Inc.</span>
            <span className={styles.company}>Sr. Front-end developer</span>
            <p className={styles.description}>
              Mixed role: a developer with duties of creating software
              architecture and code and an aide to the technical leader,
              assisting with recruitment process and creating education plans.
            </p>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2011 – 2014</span>
          <div>
            <span className={styles.company}>Lemon Tea agency</span>
            <span className={styles.company}>
              Co-founder and front-end developer
            </span>
            <p className={styles.description}>
              As one of founders, worked on creating company vision and
              strategy. Worked closely with customers, introduced new employees
              and researched new technologies.
            </p>
          </div>
        </section>

        <div className={styles.separator} />

        <h2>education</h2>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2008 – 2013</span>
          <div>
            <span className={styles.company}>
              Computer Science – undergraduate & postgraduate
            </span>
            <span className={styles.company}>
              Białystok University of Technology
            </span>
            <span className={styles.description}>
              Specialization: real-time systems and signal processing. Engineer
              degree, thesis domain: Multimedia applications of evolutionary
              algorithms.
            </span>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.dateblock}>2008 – 2013</span>
          <div>
            <span className={styles.company}>Mathematics – undergraduate</span>
            <span className={styles.company}>
              Białystok University of Technology
            </span>
            <span className={styles.description}>
              Specialization: applied mathematics in computer science.
            </span>
          </div>
        </section>

        <div className={styles.separator} />

        <h2>skills</h2>
        <section className={styles.columned}>
          <span className={styles.titleblock}>software engineering</span>
          <div>
            <ul>
              <li>
                Experienced in processes driving large applications:
                deployments, change management, ensuring quality and reliability
                in complex applications
              </li>
              <li>
                Knowledge on various approaches to unit and automated testing
              </li>
              <li>
                Familiar with both Windows and *nix platforms, operating
                systems, source code version control systems, CI/CD platforms
                (Jenkins, CircleCI)
              </li>
              <li>
                Apart from front-end/JS devlopment: fundamentals of most popular
                programming languages (C/C++, Java) and various scripting
                languages
              </li>
              <li>
                Knowledge of data structures, algorithms, problem decomposition
                and organization, defensive coding and compilers theory
              </li>
              <li>Hobbyist in low level programming and 3D graphics</li>
            </ul>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.titleblock}>web/front-end technologies</span>
          <div>
            <ul>
              <li>
                Strong knowledge about web and browser technologies in general:
                protocols, semantics, standards, JavaScript (various dialects,
                ES5/6/7+, TypeScript), REST, GraphQL. Knows design patterns and
                various architectural solutions. Familiar with accessibility and
                SEO standards. Built server-side-generated and isomorphic
                applications
              </li>
              <li>
                Topics related to web DevOps: build tools and pipelines,
                continuous integration and deployment, container systems: Docker
                and cloud deployment. Built infrastructures using Terraform code
                against Google Cloud Platform
              </li>
              <li>
                Excellent grasp of frameworks and libraries: React, Redux, MobX,
                Nest.js, express, tailwind.css. Used to work with Angular and
                Vue despite them not being the first choice
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.titleblock}>software engineering</span>
          <div>
            <ul>
              <li>
                Experienced in processes driving large applications:
                deployments, change management, ensuring quality and reliability
                in complex applications
              </li>
              <li>
                Knowledge on various approaches to unit and automated testing
              </li>
              <li>
                Familiar with both Windows and *nix platforms, operating
                systems, source code version control systems, CI/CD platforms
                (Jenkins, CircleCI)
              </li>
              <li>
                Apart from front-end/JS devlopment: fundamentals of most popular
                programming languages (C/C++, Java) and various scripting
                languages
              </li>
              <li>
                Knowledge of data structures, algorithms, problem decomposition
                and organization, defensive coding and compilers theory
              </li>
              <li>Hobbyist in low level programming and 3D graphics</li>
            </ul>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.titleblock}>
            management and business-related skills
          </span>
          <div>
            <ul>
              <li>
                Soft interpersonal skills required for solving teamwork-related
                problems and assessing business requirements,
              </li>
              <li>
                Ability to manage small projects in the same or different
                technical domain, a proven skill of delivering expected outcomes
                in projects of up to 8 developers,
              </li>
              <li>
                Made 80+ technical interviews with candidates to various
                companies.
              </li>
            </ul>
          </div>
        </section>

        <div className={styles.separator} />

        <h2>additional</h2>
        <section className={styles.columned}>
          <span className={styles.titleblock}>open source contributor</span>
          <div>
            <ul>
              <li>
                Contributor and stakeholder in GraphQLEditor, an open-source
                platform containing tools for GraphQL APIs prototyping and
                development
              </li>
              <li>Creator and curator of coders.guide platform</li>
              <li>Various minor contributions to public repositories</li>
            </ul>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.titleblock}>
            community animator and speaker
          </span>
          <div>
            <ul>
              <li>
                Initiated and coordinated 20+ technical meetups related to web
                development in Białystok and Wrocław
              </li>{" "}
              <li>○ Co-organizer of Programistok conference,</li>
              <li>
                Gave various talks on meetups and conferences (meet.js,
                TechStolica @ Microsoft, RZEmioslo.IT etc.)
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.columned}>
          <span className={styles.titleblock}>personal interests</span>
          <div>
            <ul>
              <li>
                Frequent Asia and Europe traveller - visited over 40 countries
                to time
              </li>
              <li>
                Aviation amateur, holder of private airplane pilot license
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}
