import * as React from "react";
import styles from "./Contact.module.scss";

export default class ContactPage extends React.Component {
  render() {
    return (
      <div>
        <h2>contact</h2>
        <p className={styles.contactItems}>
          <span className={styles.label}>e-mail:</span>hubert.zub (at) gmail.com
          <br />
          <span className={styles.label}>phone number: </span>
          <code>0x300AE0E5 xor 0x048D18C0</code>
          <br />
          <span className={styles.label}>GitHub: </span>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://github.com/hzub"
          >
            github.com/hzub
          </a>
          <br />
          <span className={styles.label}>LinkedIn: </span>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.linkedin.com/in/hubert-zub/"
          >
            www.linkedin.com/in/hubert-zub
          </a>
          <br />
          <span className={styles.label}>Facebook: </span>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.facebook.com/hubert.zub/"
          >
            fb.com/hubertzub
          </a>
        </p>
      </div>
    );
  }
}
