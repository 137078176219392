import * as React from "react";
import styles from "./About.module.scss";

export default class AboutPage extends React.Component {
  render() {
    return (
      <div>
        <section className={styles.AboutHeader}>
          <div>
            <h2>hello!</h2>
            <p>
              My name is Hubert, we could have already met before - you might
              know me as:
            </p>
          </div>
          <img
            alt="Credit: Maciej Korsan"
            className={styles.Me}
            src="/me.png"
          />
        </section>
        <ul className={styles.List}>
          <li className={styles.ListItem}>
            a{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.linkedin.com/in/hubert-zub/"
            >
              experienced front-end developer
            </a>
            , specialized in enterprise apps
          </li>
          <li className={styles.ListItem}>
            an{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.github.com/hzub/"
            >
              open-source contributor
            </a>
            , taking part in quite a few projects
          </li>
          <li className={styles.ListItem}>
            an speaker and writer, creating web-focused{" "}
            <a href="/writes">content on the web</a>
          </li>
          <li className={styles.ListItem}>
            a course creator,{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.wowschool.pl/www"
            >
              teaching children how to code
            </a>
          </li>
          <li className={styles.ListItem}>
            a hobbyist traveller, often visiting Asia and{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.facebook.com/hubert.zub/photos_albums"
            >
              taking photos
            </a>
          </li>
        </ul>
        <p>
          Willing to hire me? Feel free to review{" "}
          <a href="/resume">my resume</a>.<br />
          Fancy reading something? Check out <a href="/writes">my texts</a>.
          <br />
          If you want to have a chat, you can find contact information in the{" "}
          <a href="/contact">relevant section</a>.
        </p>
        <h2>see you!</h2>
      </div>
    );
  }
}
