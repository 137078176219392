import { History, Action, Location } from "history";

export type NavItem = {
  hovered: boolean;
  position: number;
  index: number;
};

const ICON_ABOUT = String.fromCharCode(0xe800);
const ICON_TRAVELS = String.fromCharCode(0xe801);
const ICON_BOOK = String.fromCharCode(0xe803);
const ICON_NEWSPAPER = String.fromCharCode(0xe804);
const ICON_MOBILE = String.fromCharCode(0xf10b);

const createNavItem = (index: number): NavItem => {
  return {
    hovered: false,
    position: 0,
    index,
  };
};
export class NavService {
  history: History | null = null;
  public navItems = [
    { name: "about", symbol: ICON_ABOUT, ...createNavItem(0), url: "/" },
    {
      name: "stories / talks ",
      symbol: ICON_BOOK,
      ...createNavItem(1),
      url: "/writes",
    },
    {
      name: "resume",
      symbol: ICON_NEWSPAPER,
      ...createNavItem(2),
      url: "/resume",
    },
    {
      name: "travel blog",
      symbol: ICON_TRAVELS,
      ...createNavItem(3),
      url: "/blog",
    },
    {
      name: "contact",
      symbol: ICON_MOBILE,
      ...createNavItem(4),
      url: "/contact",
    },
  ];
  public listeners: ((which: number) => void)[] = [];
  public currentIndex: number;
  findCurrentUrlIndex() {
    const currentPath = window.location.pathname || "/";
    const matchingItem = this.navItems.find(
      (n) => n.url === currentPath.toLowerCase()
    );
    if (matchingItem) {
      return this.navItems.indexOf(matchingItem);
    }
    return 0;
  }
  constructor() {
    this.currentIndex = this.findCurrentUrlIndex();
  }
  setHistory(history: any) {
    this.history = history;
    if (this.history) {
      this.history.listen((location: Location, action: Action) => {
        this.activate(this.findCurrentUrlIndex());
        window.ga("set", "page", location.pathname + location.search);
        window.ga("send", "pageview");
      });
    }
  }

  onChange(cb: (which: number) => void) {
    this.listeners.push(cb);
    return () => {
      this.listeners = this.listeners.filter((c) => c !== cb);
    };
  }
  activate(which: number) {
    this.listeners.forEach((l) => l(which));
  }
}

export const navService = new NavService();
