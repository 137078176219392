import * as React from 'react';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { withRouter, RouteComponentProps, Route, Switch } from 'react-router';
import styles from './Content.module.scss';
import AboutPage from '../../pages/About/About';
import ResumePage from '../../pages/Resume/Resume';
import WritesPage from '../../pages/Writes/Writes';
import ContactPage from '../../pages/Contact/Contact';

class Content extends React.Component<RouteComponentProps> {
  render() {
    return (
      <ScrollContainer className={styles.ScrollWrapper}>
        <div>
          <Switch>
            <Route path="/resume" render={() => <ResumePage />} />
            <Route path="/writes" render={() => <WritesPage />} />
            <Route path="/contact" render={() => <ContactPage />} />
            <Route><AboutPage /></Route>
          </Switch>
        </div>
      </ScrollContainer>

   );
  }
}

export default withRouter(Content);