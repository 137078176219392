import * as React from "react";
import styles from "./Nav.module.scss";
import { NavLink } from "react-router-dom";

const NAV_ITEMS = [
  // { title: 'home', url: '/' },
  { title: "about", url: "/" },
  { title: "articles / talks", url: "/writes" },
  { title: "resume", url: "/resume" },
  { title: "travel blog", externalurl: "http://blog.hubertzub.com" },
  { title: "contact", url: "/contact" },
];

export default class Nav extends React.Component {
  render() {
    return (
      <nav className={styles.nav}>
        <ul>
          {NAV_ITEMS.map((navItem, index) => (
            <li key={index}>
              {navItem.url ? (
                <NavLink
                  to={navItem.url}
                  className={styles.navLink}
                  activeClassName={styles.active}
                  exact
                >
                  {navItem.title}
                </NavLink>
              ) : (
                <a
                  href={navItem.externalurl}
                  target="_blank"
                  className={`${styles.navLink} ${styles.external}`}
                  rel="noopener noreferrer"
                >
                  {navItem.title}
                </a>
              )}
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}
