import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App/App';
import { BrowserRouter } from 'react-router-dom';

const AppStack = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

ReactDOM.render(AppStack, document.getElementById('root'));
