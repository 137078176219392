import * as React from "react";

export default class WritesPage extends React.Component {
  render() {
    return (
      <div>
        <h2>articles</h2>
        <section>
          <ul className="listing">
            <li>
              <a
                href="https://itnext.io/why-every-beginner-front-end-developer-should-know-publish-subscribe-pattern-72a12cd68d44"
                target="_blank"
                rel="noreferrer noopener"
              >
                Why every beginner front-end developer should know
                publish-subscribe pattern?
              </a>{" "}
              <div className="source">@ ITNEXT.io</div>
            </li>
            <li>
              <a
                href="https://levelup.gitconnected.com/working-with-front-end-tools-on-linux-and-windows-the-grand-performance-test-b51a77a71636"
                target="_blank"
                rel="noreferrer noopener"
              >
                Working with front-end tools on Linux and Windows: the grand
                performance test
              </a>{" "}
              <div className="source">@ Level Up Coding</div>
            </li>
            <li>
              <a
                href="https://itnext.io/why-concept-of-immutability-is-so-damn-important-for-a-beginner-front-end-developer-8da85b565c8e"
                target="_blank"
                rel="noreferrer noopener"
              >
                Why the concept of immutability is so awfully important for a
                beginner front-end developer?
              </a>{" "}
              <div className="source">@ ITNEXT.io</div>
            </li>
            <li>
              <a
                href="https://medium.com/@hubert.zub/working-with-front-end-tools-on-linux-and-windows-the-grand-performance-test-b51a77a71636"
                target="_blank"
                rel="noreferrer noopener"
              >
                Working with front-end tools on Linux and Windows: the grand
                performance test
              </a>
            </li>

            <li>
              <a
                href="https://medium.com/@hubert.zub/using-babel-7-and-preset-typescript-to-compile-angular-6-app-448eb1880f2c"
                target="_blank"
                rel="noreferrer noopener"
              >
                Using new Babel 7 and preset-typescript to compile Angular 6 app
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@hubert.zub/immutable-objects-in-javascript-made-really-simple-with-immer-42348bbee700"
                target="_blank"
                rel="noreferrer noopener"
              >
                Immutable objects in JavaScript made really simple with immer!
              </a>
            </li>
            <li>
              <a
                href="https://blog.logrocket.com/from-front-end-developer-to-a-devops-an-intro-to-ci-cd-7a8a8713fb34/"
                target="_blank"
                rel="noreferrer noopener"
              >
                From front-end developer to a DevOps: An intro to CI/CD
              </a>{" "}
              <div className="source">@ LogRocket blog</div>
            </li>
          </ul>
        </section>

        <h2>talks</h2>
        <section>
          <ul className="listing">
            <li>
              <strong>JS is for Javascript, O is for observability</strong>{" "}
              <div className="source">@ future.conf, Kraków</div>
            </li>
            <li>
              <strong>Introduction to Nx monorepositories</strong>{" "}
              <div className="source">@ meet.js, Białystok</div>
            </li>
            <li>
              <strong>Why Programmers Don’t Understand Requirements?</strong>{" "}
              <div className="source">@ Rzemiosło IT, Rzeszów</div>
            </li>
            <li>
              <strong>
                Dependency Injection without framework in 10 minutes
              </strong>{" "}
              <div className="source">@ meet.js, Białystok</div>
            </li>
            <li>
              <strong>
                Web Developer in the world of windows - Win 10 apps written in
                JavaScript.
              </strong>{" "}
              <div className="source">@ Techstolica, Microsoft HQ</div>
            </li>
          </ul>
        </section>
        <h2>courses & academic stuff</h2>
        <section>
          <ul className="listing">
            <li>
              2020 -{" "}
              <a
                href="https://wowschool.pl/www"
                target="_blank"
                rel="noreferrer noopener"
              >
                Creating websites and programming WWW applications for children
              </a>{" "}
              <div className="source">@ WOW School</div>
            </li>
            <li>
              2021 -{" "}
              <a
                href="https://tester.wi.pb.edu.pl/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Lecturer on postgraduate studies
              </a>{" "}
              <div className="source">@ Białystok Technical University</div>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}
